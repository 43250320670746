export const environment = {
  production: true,
  baseURL: 'https://fuelexpress7-api.roomruler.com/',
  apiURL: 'https://fuelexpress7-api.roomruler.com/',
  mediaURL: 'https://fuelexpress7-api.roomruler.com/file/',
  wsURL: 'wss://fuelexpress7-api.roomruler.com',
  authToken: 'anything',
  encryptionKey: 'aisidns8882IOUm2',
  ipUrl: 'https://api.ipify.org?format=json',
  versionNo: '1.0.0'
};
