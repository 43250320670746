import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WebcamModule } from 'ngx-webcam';
import { WebcamComponent } from 'src/app/shared/components/webcam/webcam.component';
import { ConfirmModalComponent } from '../components/confirm-modal/confirm-modal.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { SupportModalComponent } from '../components/supportmodal/supportmodal.component';
import { IonicModule } from '@ionic/angular';
import { FormsModule } from '@angular/forms';
import { ClockInComponent } from '../components/employees/clock-in/clock-in.component';
import { ZXingScannerModule } from '@zxing/ngx-scanner';
import { ScannerComponent } from '../components/scanner/scanner.component';
import { IdScannerComponent } from '../components/id-scanner/id-scanner.component';



@NgModule({
  declarations: [
    SupportModalComponent,
    WebcamComponent,
    ConfirmModalComponent,
    ClockInComponent,
    ScannerComponent,
    IdScannerComponent
  ],
  imports: [
    CommonModule,
    IonicModule,
    FormsModule,
    WebcamModule,
    FontAwesomeModule,
    ZXingScannerModule
  ],
  exports: [
    SupportModalComponent,
    WebcamComponent,
    ConfirmModalComponent,
    ClockInComponent,
    ScannerComponent,
    IdScannerComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class SharedModule { }
