import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { ApiService } from './shared/services/api-services/api.service';
import { WebsocketService } from './shared/services/messaging/websocket.service';
import { NfcService } from './shared/services/common-services/nfc.service';
import { ShiftService } from './shared/services/model-services/shift.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  private numberOfSeconds: number = 0;

  private scannedData: string = '';
  private lastKeyPressTime: number = 0;
  private keyPressTimeout: number = 300; // timeout in ms

  isShiftOpen: any;
  memberTagNumber: any = '';

  @HostListener('window:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    const currentTime = new Date().getTime();
    if (currentTime - this.lastKeyPressTime > this.keyPressTimeout) {
      // Reset scanned data if the interval between key presses is too long
      this.scannedData = '';
    }
    this.lastKeyPressTime = currentTime;
    if (!isNaN(Number(event.key))) {
      // If the key is a number, add it to the scanned data
      this.scannedData += event.key;
    } else if (event.key === 'Enter' && this.scannedData.length > 0) {
      // Log the scanned data if Enter key is pressed
      this.processMemberCheckIn(this.scannedData);
      this.scannedData = ''; // Reset after logging
    }
  }

  constructor(
    private _idle: Idle,
    private router: Router,
    private api: ApiService,
    private wsService: WebsocketService,
    private shiftService: ShiftService,
    private toast: ToastrService
  ) { }

  ngOnInit(): void {
    this.getSoftwareConfigurationSettings();
  }

  // Get Software Settings
  getSoftwareConfigurationSettings() {
    this.api.getSoftwareSettings('software-settings').then((res: any) => {
      const data = res[0];
      this.numberOfSeconds = data.userTimeout;
      this.setIdleTimeout();
    });
  }

  // Set Idle Information
  setIdleTimeout() {
    // Idle Information
    this._idle.setIdle(this.numberOfSeconds);

    this._idle.setTimeout(this.numberOfSeconds);

    this._idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    this._idle.onIdleStart.subscribe(() => {
      // show the modal
    });

    this._idle.onIdleEnd.subscribe(() => {
      // hide the modal
    });

    this._idle.onTimeoutWarning.subscribe((secondsLeft: number) => {
      // Update the warning message
    });

    this._idle.onTimeout.subscribe(() => {
      // Hide the modal, log out, do something else
      sessionStorage.clear();
      this.router.navigateByUrl('/login');
    });

    this._idle.watch();
  }

  // Start Processing the Member for Check-In
  async processMemberCheckIn(tagNumber: string) {
    if (tagNumber) {
      this.memberTagNumber = tagNumber;
      await this.checkForOpenShift();
    }
  }

  // Check For Open Shift
  async checkForOpenShift() {
    this.shiftService.isOpen().then(response => {
      this.isShiftOpen = response;
      if (this.isShiftOpen) {
        this.memberCheckIn();
      }
    });
  }

  // Member Check-In
  memberCheckIn() {
    this.shiftService.checkIn({ tagId: this.memberTagNumber }).then(member => {
      if (member.message === 'Member Check-in Successful!') {
        this.toast.success('Scanned Member Checked In!');
      } else {
        this.toast.error('Error During Member Scan Check-In!');
      }

    });
  }
}
