import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { v4 as uuidv4 } from 'uuid';
import { Observable } from 'rxjs';
import { DeviceDetectorService } from 'ngx-device-detector';

@Injectable({
  providedIn: 'root'
})
export class DeviceService {

  private ipApiUrl = 'https://api.ipify.org?format=json'; // You can change this to any other IP API
  private localStorageKey = 'device-uuid';
  public deviceIP = '';
  public deviceUID = '';

  constructor(private http: HttpClient, private deviceDetectorService: DeviceDetectorService) {}

  private generateUUID(): string {
    let uuid = localStorage.getItem(this.localStorageKey);
    if (!uuid) {
      uuid = uuidv4();
      localStorage.setItem(this.localStorageKey, uuid);
    }
    return uuid;
  }

  getDeviceInfo(): Observable<{ ip: string, uid: string }> {
    return new Observable(observer => {
      this.http.get<{ ip: string }>(this.ipApiUrl).subscribe(ipData => {
        const uid = this.generateUUID();
        this.deviceUID = uid;
        this.deviceIP = ipData.ip;
        observer.next({ ip: ipData.ip, uid: uid });
        observer.complete();
      }, error => {
        observer.error(error);
      });
    });
  }
}
