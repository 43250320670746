import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './shared/guards/auth.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'dashboard',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/dashboard/dashboard.module').then(m => m.DashboardPageModule),
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'members',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/members/members.module').then(m => m.MembersPageModule),
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'vendors',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/vendors/vendors.module').then(m => m.VendorsPageModule),
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'machines',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/machines/machines.module').then( m => m.MachinesPageModule),
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then(m => m.LoginPageModule),
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then(m => m.HomePageModule),
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'shift',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/shift/shift.module').then( m => m.ShiftPageModule),
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'expenses',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/expenses/expenses.module').then( m => m.ExpensesPageModule),
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'reports',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/reports/reports.module').then( m => m.ReportsPageModule),
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'repairs',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/repairs/repairs.module').then( m => m.RepairsPageModule),
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'matches',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/matches/matches.module').then( m => m.MatchesPageModule),
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'ticket-out',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/ticket-out/ticket-out.module').then( m => m.TicketOutPageModule),
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'raffles',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/raffles/raffles.module').then( m => m.RafflesPageModule),
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'bonus',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/bonus/bonus.module').then( m => m.BonusPageModule),
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'payroll',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/payroll/payroll.module').then( m => m.PayrollPageModule),
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'marketing',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/marketing/marketing.module').then( m => m.MarketingPageModule),
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'settings',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/settings/settings.module').then( m => m.SettingsPageModule),
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'safe',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/safe/safe.module').then( m => m.SafePageModule),
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'pull',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/pull/pull.module').then( m => m.PullPageModule),
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'readings',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/readings/readings.module').then( m => m.ReadingsPageModule),
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'messages',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/messages/messages.module').then( m => m.MessagesPageModule),
    runGuardsAndResolvers: 'always'
  },


];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
