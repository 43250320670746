import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

interface WebSocketMessage {
  type: string;
  userId: string;
  targetUserId?: string;
  content?: string;
}

@Injectable({
  providedIn: 'root'
})
export class WebsocketService {
  private ws!: WebSocket;
  private messageSubject: Subject<any> = new Subject();
  private reconnectInterval = 5000; // 5 seconds
  private userId!: string;
  private wsUrl: string = environment.wsURL;

  connect(userId: any) {
    if (!userId) {
      console.error('User ID is not available');
      return;
    }
    
    this.userId = userId;
    this.ws = new WebSocket(this.wsUrl);

    this.ws.onopen = () => {
      this.registerUser(this.userId);
    };

    this.ws.onmessage = (event) => {
      const message = JSON.parse(event.data);
      this.messageSubject.next(message);
    };

    this.ws.onclose = () => {
      console.warn('WebSocket connection closed, attempting to reconnect');
      setTimeout(() => this.connect(this.userId), this.reconnectInterval);
    };

    this.ws.onerror = (error) => {
      console.error('WebSocket error:', error);
      this.ws.close();
    };
  }

  private registerUser(userId: string) {
    if (this.ws && this.ws.readyState === WebSocket.OPEN) {
      const registerMessage: WebSocketMessage = { type: 'register', userId };
      this.ws.send(JSON.stringify(registerMessage));
    } else {
      console.error('WebSocket is not open');
    }
  }

  sendMessage(message: WebSocketMessage) {
    if (this.ws && this.ws.readyState === WebSocket.OPEN) {
      this.ws.send(JSON.stringify(message));
    } else {
      console.error('WebSocket is not open');
    }
  }

  getMessages(): Observable<any> {
    return this.messageSubject.asObservable();
  }
}
